import React, { useState } from 'react';
import designBanner from '../photos/banner-design.jpeg';
import design1 from "../photos/701.jpg";
import design2 from "../photos/702.jpg";
import design3 from "../photos/703.jpg";
import design4 from "../photos/704.jpg";
import design5 from "../photos/705.jpg";
import design6 from "../photos/706.jpg";
import design7 from "../photos/707.jpg";
import design8 from "../photos/708.jpg";
import design9 from "../photos/709.jpg";
import design10 from "../photos/710.jpg";
import design11 from "../photos/711.jpg";
import design12 from "../photos/712.jpg";
import design13 from "../photos/713.jpg";
import design14 from "../photos/714.jpg";
import design15 from "../photos/715.jpg";
import design16 from "../photos/716.jpg";

import Modal from './Modal';
import '../styles/modalView.css';

const designs = [
    { name: "design1", src: design1 },
    { name: "design2", src: design2 },
    { name: "design3", src: design3 },
    { name: "design4", src: design4 },
    { name: "design5", src: design5 },
    { name: "design6", src: design6 },
    { name: "design7", src: design7 },
    { name: "design8", src: design8 },
    { name: "design9", src: design9 },
    { name: "design10", src: design10 },
    { name: "design11", src: design11 },
    { name: "design12", src: design12 },
    { name: "design13", src: design13 },
    { name: "design14", src: design14 },
    { name: "design15", src: design15 },
    { name: "design16", src: design16 },
];

export default function Design() {
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [initialStyle, setInitialStyle] = useState('');

    const handleImageClick = (e, index) => {
        const rect = e.target.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        const initialStyle = `translate(${rect.left + scrollLeft}px, ${rect.top + scrollTop}px) scale(0.1)`;
        setSelectedImageIndex(index);
        setInitialStyle(initialStyle);
    };

    const handleCloseModal = () => {
        setSelectedImageIndex(null);
    };

    const handleNavigate = (direction) => {
        setSelectedImageIndex((prevIndex) => {
            const newIndex = (prevIndex + direction + designs.length) % designs.length;
            return newIndex;
        });
    };

    return (
        <>
            {/* <img 
                src={designBanner} 
                alt="banner" 
                className="banner" 
            /> */}
            <div className="design-grid">
                {designs.map((design, index) => (
                    <img 
                        key={index} 
                        src={design.src} 
                        alt={design.name} 
                        className="design-image" 
                        onClick={(e) => handleImageClick(e, index)}
                    />
                ))}
            </div>
            {selectedImageIndex !== null && (
                <Modal
                    show={selectedImageIndex !== null}
                    onClose={handleCloseModal}
                    src={designs[selectedImageIndex].src}
                    onNavigate={handleNavigate}
                />
            )}
        </>
    );
}
