import React from "react";
import '../style.css';
import instaIcon from '../icons/insta.png'; // Import the Instagram icon

export default function Footer() {
    return (
        <div className="footer">
            <div className="footer-right">
                <span>© 2024 Alex Tattoo. All rights reserved.</span>
            </div>
            <div className="footer-left">
                <a href="https://www.instagram.com/cgtattoo.alex/" target="_blank" rel="noopener noreferrer" className="instagram-link">
                    <img src={instaIcon} alt="Instagram" className="instagram-icon" />
                </a>
            </div>
        </div>
    );
}
