import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';
import heroLogo from "../icons/hero-logo.png";

export default function Header()
{
    return (
        <>
            <div className="header">
                <Link to="/" className="header-title-link">
                <h1>CG&nbsp;TAT&nbsp;&nbsp;&nbsp;TOO&nbsp;ALEX</h1>
                    <img src={heroLogo} alt='hero-logo' className='hero-logo' /> 
                </Link>
                <img src={heroLogo} alt="mobile-logo" className='mobile-logo' /> 
                <ul className="hero-list">
                    <li><Link to="/booking"><button>BOOKING</button></Link></li>
                    <li><Link to="/design"><button>DESIGNS</button></Link></li>
                    <li><Link to="/tattoos"><button>TATTOOS</button></Link></li>
                    <li><Link to="/about"><button>ABOUT ME</button></Link></li>
                </ul>
            </div>
        </>
    );
}
