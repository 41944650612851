import React, { useEffect, useRef, useState } from 'react';
import Form from './Form.js';
import QA from './QA.js';
import '../style.css';
import Arrow from "../icons/arrow4.gif";
import QuestionMark from "../icons/question.gif";

export default function Booking() {
    const [animate, setAnimate] = useState(false);
    const questionImgRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setAnimate(true);
                    observer.unobserve(entry.target); // Stop observing once the animation has started
                }
            });
        }, {
            threshold: 0.1 // Trigger when 10% of the image is visible
        });

        if (questionImgRef.current) {
            observer.observe(questionImgRef.current);
        }

        return () => {
            if (questionImgRef.current) {
                observer.unobserve(questionImgRef.current);
            }
        };
    }, []);

    return (
        <div className='booking-page'>
            <div className='form-container'>
                <p>
                    To set up an appointment fill out the form  
                    below, I will revert back to you with my 
                    availability and a quote within 24 hours. 
                </p>
                <img src={Arrow} alt="Arrow" className='arrow-image' />
                <Form />
            </div>
            <div className='qa-container'>
                <img
                    src={QuestionMark}
                    alt="Question"
                    className={`question-img ${animate ? 'animate' : ''}`}
                    ref={questionImgRef}
                />
                <QA />
            </div>
        </div>
    );
}
