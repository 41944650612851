import React, { useEffect, useRef } from 'react';
import "../styles/modalView.css"; // Ensure the correct path to your CSS file
import navStraw from "../icons/nav_straw.png";
import close from "../icons/x.png";

const Modal = ({ show, onClose, src, onNavigate }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        if (modalRef.current) {
            if (show) {
                modalRef.current.classList.add('show');
            } else {
                modalRef.current.classList.remove('show');
            }
        }
    }, [show]);

    if (!show) {
        return null;
    }

    const handlePrevClick = (e) => {
        e.stopPropagation();
        onNavigate(-1);
    };

    const handleNextClick = (e) => {
        e.stopPropagation();
        onNavigate(1);
    };

    return (
        <div className="modal-overlay show" onClick={onClose}>
            <div className="modal-content" ref={modalRef} onClick={(e) => e.stopPropagation()}>
                <button className="modal-nav prev" onClick={handlePrevClick}>
                    <img src={navStraw} alt='straw-left' className='straw-left' />
                </button>
                <img src={src} alt="Full size design" className="modal-image" />
                <button className="modal-nav next" onClick={handleNextClick}>
                    <img src={navStraw} alt='straw-right' className='straw-right' />
                </button>
                <button className="modal-close" onClick={onClose}>
                    <img src={close} alt="close-sign" className='close-sign' />
                </button>
            </div>
        </div>
    );
};

export default Modal;
