import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Header from "./Components/Header.js";
import Booking from "./Components/Booking.js";
import Design from "./Components/Design.js";
import Tattoos from "./Components/Tattoos.js";
import About from "./Components/AboutUs.js";
import Footer from "./Components/Footer.js";
import MetaPixel from "./Components/MetaPixel";

function App() {
    return (
        <Router>
            <MetaPixel /> {/* Meta Pixel code will be executed here */}
            <div className="app-container">
                <Header />
                <div className="content">
                    <Routes>
                        <Route path="/booking" Component={Booking} /> 
                        <Route path="/design" Component={Design} /> 
                        <Route path="/tattoos" Component={Tattoos} />
                        <Route path="/about" Component={About} /> 
                        <Route path="/" element={<Navigate to="/booking" />} /> 
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
