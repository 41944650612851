import React from 'react';
import logo from '../icons/alex-logo.png'; // Make sure to replace this with the correct path to your logo
import '../style.css'; // Ensure this imports your styles
import Light1 from "../icons/lights1.png";
import Light2 from "../icons/lights2.png";
 
export default function About() {
    return (
        <div class="about-container">
            <div class="about-header">
                <img src={logo} alt="Logo" class="about-logo" />
                <div class="about-info">
                    <img src={Light1} alt="Light1" className='light1-img' />
                    <h2>Alex Tattoo</h2>
                    <img src={Light2} alt="Light2" className='light2-img' /> 
                    <div class="inline-text">
                        {/* <p>o.chaliienko@gmail.com  </p> */}
                        <p> instagram: @cgtattoo.alex</p>
                    </div>
                </div>
            </div>
            <div className="about-text">
                <p>
                Firstly, thank you for being here and reading this! My name is Alex, and I am a tattoo artist from Kyiv, Ukraine based in Los Angeles. I specialize in realism tattoos created using 3D rendering. Each design is handcrafted, from the initial model to how it will look on you. All my creations are custom-made without using AI tools like GPT, ensuring full control over the process to develop the perfect tattoo design for you.
                </p>
                <p>
                Drawing and design have always been my passions. I graduated with a degree in design and have worked in 3D modeling and music video production. From the start, I knew I would eventually pursue tattooing and strive for excellence in this field. My background in 3D modeling and design helps me create unique and captivating tattoos. My main goal is to bring your vision to life on your skin and make sure you're happy with the result.
                </p>
                <p>
                If you're interested to get inked, please fill out the form, and I will gladly get in touch with you.
                </p>
                <p>Thanks!</p>
            </div>
        </div>
    );
}
