import React, { useState } from 'react';
import Modal from './Modal';
import '../styles/modalView.css';
import Tattoo1 from "../photos/ph1.jpg";
import Tattoo2 from "../photos/ph2.jpg";
import Tattoo3 from "../photos/ph3.jpg";
import Tattoo4 from "../photos/ph4.jpg";
import Tattoo5 from "../photos/ph5.jpg";
import Tattoo6 from "../photos/ph6.jpg";
import Tattoo7 from "../photos/ph7.jpg";
import Tattoo8 from "../photos/ph8.jpg";
import Tattoo9 from "../photos/ph9.jpg";

const tattoos = [
    { name: "tattoo1", src: Tattoo1 },
    { name: "tattoo2", src: Tattoo2 },
    { name: "tattoo3", src: Tattoo3 },
    { name: "tattoo4", src: Tattoo4 },
    { name: "tattoo5", src: Tattoo5 },
    { name: "tattoo6", src: Tattoo6 },
    { name: "tattoo7", src: Tattoo7 },
    { name: "tattoo8", src: Tattoo8 },
    { name: "tattoo9", src: Tattoo9 }
];

export default function Tattoos() {
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
    };

    const handleCloseModal = () => {
        setSelectedImageIndex(null);
    };

    const handleNavigate = (direction) => {
        setSelectedImageIndex((prevIndex) => {
            const newIndex = (prevIndex + direction + tattoos.length) % tattoos.length;
            return newIndex;
        });
    };

    return (
        <>
            <div className="tattoo-grid">
                {tattoos.map((tattoo, index) => (
                    <img 
                        key={index} 
                        src={tattoo.src} 
                        alt={tattoo.name} 
                        className="tattoo-image" 
                        onClick={() => handleImageClick(index)}
                    />
                ))}
            </div>
            {selectedImageIndex !== null && (
                <Modal
                    show={selectedImageIndex !== null}
                    onClose={handleCloseModal}
                    src={tattoos[selectedImageIndex].src}
                    onNavigate={handleNavigate}
                />
            )}
        </>
    );
}
