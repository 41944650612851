import React, { useRef, useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/Form.css';

const MAX_FILE_SIZE = 10 * 5000 * 5000; // 10 MB

const Form = () => {
    const form = useRef();
    const [message, setMessage] = useState('');
    const [dates, setDates] = useState([null]);
    const [files, setFiles] = useState([null]);
    const [fileNames, setFileNames] = useState(['']);

    const sendMessage = async (e) => {
        e.preventDefault()

        const formData = new FormData(form.current);
        const messageContent = `
🎉 Hey, Alex. You have a new booking! 👽 Check info below:

Name: ${formData.get('user_name')}\n
Age: ${formData.get('user_age')}\n
Tattoo ideas/Flash: ${formData.get('idea')}\n
Size: ${formData.get('size')}\n
Placement: ${formData.get('placement')}\n
Email: ${formData.get('instagram')}\n
Phone: ${formData.get('phone')}\n
Available dates: ${dates.map(date => date ? date.toLocaleDateString() : '').join(', ')}\n
Reference images: 👇 👇 👇
        `;

        formData.append('message', messageContent);

        // Log the formData keys and values
        for (let [key, value] of formData.entries()) {
            console.log(`${key}:`, value);
        }

        try {
            await axios.post('/.netlify/functions/send-message', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessage('SUCCESS! Your appointment request has been sent.');
            form.current.reset();
            setDates([null]);
            setFiles([null]);
            setFileNames(['']);
        } catch (error) {
            if (error.response && error.response.status === 413) {
                setMessage('Oops, it looks like you put too many images for reference.Please keep the total image size under 4.5 MB.');}
            else {setMessage(`FAILED... ${error.message}`);}
        }
    };

    const addDate = () => {
        setDates([...dates, null]);
    };

    const updateDate = (date, index) => {
        const newDates = [...dates];
        newDates[index] = date;
        setDates(newDates);
    };

    const addFileInput = () => {
        setFiles([...files, null]);
        setFileNames([...fileNames, '']);
    };

    const updateFile = (file, index) => {
        if (file.size > MAX_FILE_SIZE) {
            alert('File size exceeds the 10MB limit.');
            return;
        }

        const newFiles = [...files];
        newFiles[index] = file;
        setFiles(newFiles);

        const newFileNames = [...fileNames];
        newFileNames[index] = file ? file.name : '';
        setFileNames(newFileNames);
    };

    const handleFileUpload = (index) => {
        document.getElementById(`fileInput${index}`).click();
    };

    // textarea function for wrapping text
    const autoGrow = (element) => {
        element.style.height = '40px'; // Reset the height
        element.style.height = `${element.scrollHeight}px`;
        if (element.value.trim() === '') {
            element.style.height = '40px';
        }
    };

    return (
        <div className="booking">
            <form ref={form} onSubmit={sendMessage} encType="multipart/form-data">
                <input type="text" name="user_name" placeholder='Name' required />
                <input type="text" name="user_age" placeholder='Age' required />
                <textarea
                    name="idea"
                    placeholder="Tattoo ideas / Flash #"
                    required
                    onInput={(e) => autoGrow(e.target)}
                ></textarea>
                <input type="text" name="size" placeholder='Approximate size in cm/inch' required />
                <input type="text" name="placement" placeholder='Placement' required />

                <div className="file-input-wrapper">
                    {files.map((file, index) => (
                        <div className="file-input-container" key={index}>
                            <input
                                type="file"
                                id={`fileInput${index}`}
                                style={{ display: 'none' }}
                                onChange={(e) => updateFile(e.target.files[0], index)}
                                name="photos"
                            />
                            <div className="file-upload-label" onClick={() => handleFileUpload(index)}>
                                {fileNames[index] ? (
                                    <span style={{ color: 'black' }}>{fileNames[index]}</span>
                                ) : 'Reference image'}
                            </div>
                        </div>
                    ))}
                    <button type="button" onClick={addFileInput} className="add-file-button">+</button>
                </div>

                <input type="text" name="instagram" placeholder='Instagram account' required />
                <input type="text" name="phone" placeholder='Phone number' required />

                <div className="date-picker-wrapper">
                    {dates.map((date, index) => (
                        <div className="date-picker-container" key={index}>
                            <DatePicker
                                selected={date}
                                onChange={(date) => updateDate(date, index)}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Available date"
                            />
                        </div>
                    ))}
                    <button type="button" onClick={addDate} className="add-date-button">+</button>
                </div>
                <input type="submit" value="REQUEST  AN  APPOINTMENT" />
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default Form;
