import React, { useState } from 'react';
import '../style.css';

export default function QA() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className='qa-form'>
            <h2>QA</h2>
            <ul>
                {questions.map((item, index) => (
                    <li key={index}>
                        <div className='question-container' onClick={() => handleToggle(index)}>
                            <p className={`question ${activeIndex === index ? 'active' : ''}`}>
                                {item.question}
                            </p>
                            <span className={`plus-sign ${activeIndex === index ? 'active' : ''}`}>+</span>
                        </div>
                        <p className={`answer ${activeIndex === index ? 'show' : ''}`} dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </li>
                ))}
            </ul>
        </div>
    );
}

const questions = [
    {
        question: "What's your deposit policy?",
        answer: "A $100 deposit is required before any session, regardless of the tattoo cost. The deposit is non-refundable if you cancel within 24 hours of your session or do not show up. For custom designs, the deposit is also non-refundable if you cancel."
    },
    {
        question: "How do you charge?",
        answer: "Payment is due after the session. The deposit is included in the final amount. Accepted payment methods are Zelle, Venmo, and cash."
    },
    {
        question: "What should I do if something need to be fixed?",
        answer: "Free touch-ups are available after at least 5 weeks of healing. Please provide photos of your tattoo before scheduling a touch-up. Realism tattoos often require touch-ups, which is normal."
    },
    {
        question: "Do you do custom tattoos?",
        answer: "I create custom designs using Blender and Zbrush. Work on custom designs begins after receiving the deposit. Custom designs are exclusive and won’t be reused for other clients."
    },
    {
        question: "How do you determine the cost of a tattoo?",
        answer: "My minimum charge is 150$ per price. Price vary widely depending on your tattoo, and I'll provide a detailed estimate to fit your specific needs before you confirm your appointment."
    },
    {
        question: "Where are you located?",
        answer: "Mirai Tattoo Gallery <br />616 S La Brea Ave, Los Angeles, CA 90036"
    }
];
